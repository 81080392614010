import React, { useEffect, useRef } from 'react';
import classnames from 'classnames';

import { TrustPilotComponentFragment } from '../../../../graphql.schema';

import * as styles from '../StrapiReviewsIOComponent/StrapiReviewsIOComponent.module.scss';

type StrapiTrustPilotComponentPropType = TrustPilotComponentFragment & {
  hasRTL?: boolean;
  loadDefer?: boolean;
};
export const StrapiTrustBoxComponent: React.FC<StrapiTrustPilotComponentPropType> = ({
  heading,
  script,
  hasRTL = false,
  loadDefer,
}) => {
  const trustBoxRef = useRef(null);
 
  useEffect(() => {
    if(typeof window!=='undefined' && window.Trustpilot) {
    // Parse the HTML string to get the script and div elements
    const parser = new DOMParser();
    const doc = parser.parseFromString(script?.htmlCode, 'text/html');

    // Get the script element and the TrustBox div element
    const scriptElement = doc.querySelector('script');
    const trustBoxDiv = doc.querySelector('.trustpilot-widget');

    // Append the TrustBox div to the ref element
    if (trustBoxRef.current) {
        trustBoxRef.current.appendChild(trustBoxDiv);
        window.Trustpilot.loadFromElement(trustBoxDiv);
    }
    // Append the script element to the document's body to load the script
    if (scriptElement) {
        document.body.appendChild(scriptElement);
    }
  }
}, []);

  return (
    <div className={classnames(hasRTL ? 'mt-5' : '', styles.root)}>
      <h2 className={classnames('title is-2 has-text-centered', styles.title)}>{heading}</h2>
      <div ref={trustBoxRef}></div>
    </div>
  );
};
