import React from 'react';

import {
  StrapiCareersGallery,
  StrapiColumnsComponent,
  StrapiCourseCTAPanel,
  StrapiDetailedPricingPlan,
  StrapiEmbedComponent,
  StrapiEnglishLevelsAccordion,
  StrapiEnglishLevelsTable,
  StrapiHeroComponent,
  StrapiHeroCourse,
  StrapiHeroMap,
  StrapiImageAndTextComponent,
  StrapiInlineCtaComponent,
  StrapiInlineHubspotForm,
  StrapiLevelsComponent,
  StrapiLocationPanel,
  StrapiLogoGallery,
  StrapiMap,
  StrapiMeetTheTeamGallery,
  StrapiPricingPlanComponent,
  StrapiPromotionsPanelComponent,
  StrapiQuoteAndImage,
  StrapiReviewsIOComponent,
  StrapiRichList,
  StrapiRichTextComponent,
  StrapiSchoolInfoBox,
  StrapiScrollingStatementComponent,
  StrapiSimpleTitleComponent,
  StrapiTeacherAndCoaches,
  StrapiTextAndSplitImages,
  StrapiTextComponent,
  StrapiThreeBlocksAndImage,
  StrapiTwoColumns,
  StrapiTwoColumnsAndLinks,
  StrapiVideoAndTextComponent,
  StrapiCourseComparison,
  StrapiSchoolsList,
} from '../StrapiComponents';
import {StrapiTrustBoxComponent} from '../StrapiComponents/StrapiTrustBoxComponent/index'
import { ModalRawHTML } from '../RawHTML/ModalRawHTML';
import { PageComponentsFragment, SchoolCardFragment } from '../../../graphql.schema';
// import { mai } from '../../lib/mapV4toV3';

type ComponentRendererProps = {
  component: NonNullable<PageComponentsFragment>;
  associateFunction?: Function | null; 
  schoolSlugBase?: string;
  hasRTL?: boolean;
  relatedList?: { schools?: SchoolCardFragment[] };
  location?: Location
};

const ComponentRenderer: React.FC<ComponentRendererProps> = ({
  component,
  hasRTL = false,
  schoolSlugBase = '',
  associateFunction,
  location
}) => {
  switch (component.__typename) {
    case 'Strapi_ComponentPageHtml':
      return (
        <div className="container">
          <ModalRawHTML html={component.htmlCode} />
        </div>
      );
    case 'Strapi_ComponentPageCallToAction':
      return (
        <div className="container">
          <StrapiInlineCtaComponent {...component} />
        </div>
      );
    case 'Strapi_ComponentPageEmbedCode':
      return (
        <div className="container">
          <StrapiEmbedComponent {...component} />
        </div>
      );
    case 'Strapi_ComponentPageHero':
      return (
        <div className="container">
          <StrapiHeroComponent {...component} />
        </div>
      );
    case 'Strapi_ComponentPageImageAndText':
      return <StrapiImageAndTextComponent hasRTL={hasRTL} {...component} />;
    case 'Strapi_ComponentPageVideoAndText':
      return <StrapiVideoAndTextComponent {...component} />;
    case 'Strapi_ComponentPageSimpleTitle':
      return (
        <div className="container">
          <StrapiSimpleTitleComponent {...component} />
        </div>
      );
    case 'Strapi_ComponentPageText':
      return (
        <div className="container">
          <StrapiTextComponent {...component} />
        </div>
      );
    case 'Strapi_ComponentPageColumns':
      return (
        <div className="container">
          <StrapiColumnsComponent {...component} />
        </div>
      );
    case 'Strapi_ComponentPageRichText':
      return (
        <div className="container">
          <StrapiRichTextComponent {...component} />
        </div>
      );
    case 'Strapi_ComponentPageLogoGallery':
      return (
        <div className="container">
          <StrapiLogoGallery {...component} />
        </div>
      );
    case 'Strapi_ComponentPageReviewsIoWidget':
      return <StrapiReviewsIOComponent {...component} hasRTL={hasRTL} />;
    case 'Strapi_ComponentPageTrustPilotWidget':
       return <StrapiTrustBoxComponent {...component} hasRTL={hasRTL} />;
    case 'Strapi_ComponentPageScrollingStatement':
      return (
        <div className="container">
          <StrapiScrollingStatementComponent {...component} hasRTL={hasRTL} />
        </div>
      );
    case 'Strapi_ComponentPageQuoteAndText':
      return (
        <div className="container">
          <StrapiQuoteAndImage {...component} />
        </div>
      );
    case 'Strapi_ComponentPageCourseCtaPanel':
      return (
        <div className="container">
          <StrapiCourseCTAPanel {...component} />
        </div>
      );
    case 'Strapi_ComponentPageMap':
      return (
        <div className="container">
          <StrapiMap {...component} />
        </div>
      );
    case 'Strapi_ComponentPageLocationPanel':
      return (
        <div className="container">
          <StrapiLocationPanel {...component} />
        </div>
      );
    case 'Strapi_ComponentPageEnglishLevels':
      return (
        <div className="container">
          <StrapiLevelsComponent {...component} />
        </div>
      );
    case 'Strapi_ComponentPagePricingPlanPanel':
      return <StrapiPricingPlanComponent {...component} />;
    case 'Strapi_ComponentPageTextAndSplitImages':
      return (
        <div className="container">
          <StrapiTextAndSplitImages {...component} />
        </div>
      );
    case 'Strapi_ComponentPagePromotionsCtaPanel':
      return (
        <div className="container">
          <StrapiPromotionsPanelComponent {...component} />
        </div>
      );
    case 'Strapi_ComponentPageThreeBlocksAndImage':
      return (
        <div className="container">
          <StrapiThreeBlocksAndImage {...component} />
        </div>
      );
    case 'Strapi_ComponentPageEnLvlsTbl':
    case 'Strapi_ComponentPageEnglishLevelsTable':
      return (
        <div className="container">
          <StrapiEnglishLevelsTable {...component} />
        </div>
      );
    case 'Strapi_ComponentPageEnLvlsAcd':
    case 'Strapi_ComponentPageEnglishLevelsAccordion':
      return (
        <div className="container">
          <StrapiEnglishLevelsAccordion {...component} />
        </div>
      );
    case 'Strapi_ComponentPageTwoColumns':
      return <StrapiTwoColumns {...component} />;
    case 'Strapi_ComponentPageHeroCourse':
      return (
        <div className="container">
          <StrapiHeroCourse hasRightToLeftLanguage={hasRTL} {...component} />
        </div>
      );
    case 'Strapi_ComponentPageRichList':
      return (
        <div className="container">
          <StrapiRichList {...component} />
        </div>
      );
    case 'Strapi_ComponentPageDetailedPricingPlan':
      return (
        <div className="container">
          <StrapiDetailedPricingPlan {...component} />
        </div>
      );
    case 'Strapi_ComponentPageTwoColumnsAndLinks':
      return (
        <div className="container">
          <StrapiTwoColumnsAndLinks {...component} />
        </div>
      );
    case 'Strapi_ComponentPageTeacherAndCoaches':
      return (
        <div className="container">
          <StrapiTeacherAndCoaches {...component} />
        </div>
      );
    case 'Strapi_ComponentPageMeetTheTeamGallery':
      return (
        <div className="container">
          <StrapiMeetTheTeamGallery {...component} />
        </div>
      );
    case 'Strapi_ComponentPageSchoolInfoBox':
      return (
        <div className="container">
          <StrapiSchoolInfoBox {...component} hasRTL={hasRTL} />
        </div>
      );
    case 'Strapi_ComponentPageHeroMap':
      return (
        <div className="container">
          <StrapiHeroMap {...component} />
        </div>
      );
    case 'Strapi_ComponentPageCareersGallery':
      return (
        <div className="container">
          <StrapiCareersGallery {...component} />
        </div>
      );
    case 'Strapi_ComponentPageCourseCmprsns':
    case 'Strapi_ComponentPageCourseComparison':
      return (
        <div className="container">
          <StrapiCourseComparison {...component} />
        </div>
      );
    case 'Strapi_ComponentPageInlineHubspotForm':
      return <StrapiInlineHubspotForm {...component} associateFunction={associateFunction} hasRTL={hasRTL} location={location} />;
    case 'Strapi_ComponentDataRelatedSchools':
      return <StrapiSchoolsList {...component} schoolSlugBase={schoolSlugBase} hasRTL={hasRTL} />;
    default:
      console.error('Unsupported component type', component);
      return (
        <section className="section">
          <pre>{JSON.stringify(component, null, 2)}</pre>
        </section>
      );
  }
};

export default ComponentRenderer;
