import React from 'react';
import { useIntl } from 'react-intl';
import classnames from 'classnames';
import { graphql, PageProps } from 'gatsby';

import LayoutWrapper, { intlWrapperHOC, v4tov3HOC } from '../../components/LayoutWrapper';
import SEO from '../../components/SEO/SEO';
import ComponentRenderer from '../../components/ComponentRenderer/ComponentRenderer';
import { StrapiHeroCourse } from '../../components/StrapiComponents/StrapiHeroCourse';
import Breadcrumb from '../../components/Breadcrumb';
import { StrapiFooterCtaComponent } from '../../components/StrapiComponents/StrapiFooterCta';
import { StrapiInlineCtaComponent } from '../../components/StrapiComponents/StrapiInlineCtaComponent';
import { InlineCtaComponentFragment, SingleCoursePostQuery } from '../../../graphql.schema';
import { GlobalPageContext } from '../../types';
import { v4tov3base } from '../../lib/mapV4toV3';

import * as styles from './course-post.module.scss';

type PageContextType = GlobalPageContext & {
  id: string;
};

const CoursePost: React.FC<PageProps<SingleCoursePostQuery, PageContextType>> = ({ data, pageContext }) => {
  const { course } = data.strapi;
  const { websiteLocale: websiteLocaleV3 } = pageContext;
  const websiteLocale = v4tov3base({ id: websiteLocaleV3.id, ...websiteLocaleV3.attributes });
  const intl = useIntl();

  const showFooterCta = course?.pageSettings?.showFooterCta ?? false;
  const footerCta = course?.pageSettings?.customFooterCta?.id
    ? course?.pageSettings?.customFooterCta
    : websiteLocale.footerCta;

  if (!websiteLocale || !course) return <p>Not found</p>;
  return (
    <LayoutWrapper {...pageContext}>
      <SEO
        title={course.seo?.metaTitle ?? course.pageSettings?.title ?? course.name ?? ''}
        description={course.seo?.metaDescription ?? course.pageSettings?.metaDescription ?? course.short}
        image={course.seo?.metaImage.url ?? course.image?.url}
        avoidIndexing={course.pageSettings?.avoidIndexing ?? false}
        canonicalURL={course.seo?.canonicalURL}
        keywords={course.seo?.keywords}
        metaSocial={course.seo?.metaSocial}
        structuredData={course.seo?.structuredData}
        alternatePages={pageContext?.availableRoutes}
        currentPage={pageContext}
      />
      <div className="container mt-5">
        <Breadcrumb pageContext={pageContext} lastCrumb={course.name}></Breadcrumb>

        <StrapiHeroCourse
          hasRightToLeftLanguage={websiteLocale?.hasRightToLeftLanguage}
           courseHeadingText={course.title ? course.title : intl.formatMessage({ id: 'courseHeadingText', defaultMessage: 'What you get' })}
          {...course}
        />
      </div>
      <div
        className={classnames(
          'container page-components',
          styles['pageComponents'],
          styles['courseButton'],
          websiteLocale?.hasRightToLeftLanguage ? styles['courseColumnListRtl'] : '',
        )}
      >
        {course.components?.map((component, idx) =>
          component ? (
            <ComponentRenderer
              component={component}
              key={`course-post-component-${component.__typename}-${idx}`}
              hasRTL={websiteLocale?.hasRightToLeftLanguage}
            />
          ) : null,
        )}
      </div>
      {course.callToAction && <StrapiInlineCtaComponent {...(course.callToAction as InlineCtaComponentFragment)} />}
      {showFooterCta && footerCta && (
        <div className={classnames(styles.ctaContainer)}>
          <StrapiFooterCtaComponent {...footerCta} />
        </div>
      )}
    </LayoutWrapper>
  );
};

export const query = graphql`
query SingleCoursePost($id: ID!) {
  strapi {
    course(id: $id) {
      data {
        id
        attributes {
          name
          slug
          short
          localizations {
            data {
              attributes {
                locale
              }
            }
          }
          title
          feature_type
          courseFeatures: course_features {
            feature
          }
          courseText: course_text {
              title
              description
          }
          courseCTA: course_cta {
            ...LinkComponent
          }
          image {
            ...StrapiImageEntityResponse
          }
          components {
            ...PageComponents
            __typename
            ... on Strapi_ComponentPageTrustPilotWidget {
              heading
              script {
                ...HTMLComponent
              }
            }
          }
          callToAction: call_to_action {
            ...InlineCTAComponent
          }
          pageSettings: page_settings {
            ...PageSettings
          }
          seo {
            ...SEOComponent
          }
        }
      }
    }
  }
}
`;

export default intlWrapperHOC(v4tov3HOC(CoursePost));
