import React from 'react';
import { Helmet } from 'react-helmet';

const StructuredData: React.FC<{ structuredData: { [key: string]: string } }> = ({ structuredData }) => {
  return (
    <Helmet>
      <script type="application/ld+json">{`${JSON.stringify(structuredData)}`}</script>
    </Helmet>
  );
};

export default React.memo(StructuredData);
